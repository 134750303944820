import '../styles/globals.css';
import 'xterm/css/xterm.css';

import { AppProps } from 'next/app';
import Head from 'next/head';
import {
	MantineProvider,
	MantineThemeOverride,
	ButtonStylesParams,
	MantineTheme,
	TextStylesParams,
	CardProps
} from '@mantine/core';
import Script from 'next/script';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const theme: MantineThemeOverride = {
	colorScheme: 'dark',
	components: {
		Button: {
			styles: (def: MantineTheme, params: ButtonStylesParams) => ({
				root: {
					borderRadius: params.color == 'green' && params.variant != 'subtle' ? '31px' : '10px',
					border:
						params.variant == 'subtle'
							? 'none'
							: `2px solid ${def.colors[params.color || def.primaryColor][9]}`,
					backgroundColor: `${def.colors[params.color || def.primaryColor][6]}05 !important`,
					color: `${def.colors[params.color || def.primaryColor][9]} !important`,
					transition: 'all 0.2s ease-in-out',
					'&:hover': {
						backgroundColor: `${def.colors[params.color || def.primaryColor][6]}33 !important`
					},
					'&:before': {
						borderRadius: (params.color == 'green' ? '31px' : '10px') + ' !important'
					},
					'&:focus': {
						outline: 'none',
						filter: 'brightness(1.5)'
					}
				},
				leftIcon: {
					'> svg': {
						stroke: `${def.colors[params.color || def.primaryColor][9]} !important`
					}
				}
			})
		},
		Stepper: {
			styles: (_: MantineTheme) => ({
				root: {
					width: '90%',
					height: '100%'
				},
				stepIcon: {
					backgroundColor: 'transparent',
					color: 'var(--text-color)',
					filter: 'brightness(0.8)',
					border: '2px solid var(--text-color-faded)'
				},
				stepLabel: {
					color: 'var(--text-color)'
				},
				stepDescription: {
					color: 'var(--text-color)',
					filter: 'brightness(0.8)'
				},
				separator: {
					backgroundColor: 'var(--text-color)'
				},
				content: {
					height: '100%'
				}
			})
		},
		Text: {
			styles: (_: MantineTheme, params: TextStylesParams) => ({
				root: params.color == 'blue' ? { color: 'var(--primary-color)' } : {}
			})
		},
		Input: {
			styles: (_: MantineTheme) => ({
				input: {
					backgroundColor: 'var(--background-color-1) !important',
					color: 'var(--text-color)',
					border: '1px solid var(--background-color-2)'
				},
				rightSection: {
					width: 'auto',
					marginRight: '0.5em'
				}
			})
		},
		Modal: {
			styles: (_: MantineTheme) => ({
				modal: {
					backgroundColor: 'var(--background-color)',
					color: 'var(--text-color)'
				}
			})
		},
		Card: {
			styles: (_: MantineTheme, props: CardProps) => ({
				root: {
					backgroundColor: 'var(--background-color-2)',
					color: 'var(--text-color)',
					margin: '1em',
					filter: 'brightness(0.9)',
					transition: 'all 0.2s ease-in-out',
					borderRadius: '10px',
					'&:hover:not(.solid)': {
						filter: 'none',
						borderRadius: '2px',
						transform: 'scale(1.005)'
					}
				}
			})
		},
		Checkbox: {
			styles: (_: MantineTheme) => ({
				input: {
					backgroundColor: 'var(--background-color-1)',
					border: '1px solid var(--background-color-2)'
				}
			})
		},
		Tabs: {
			styles: (_: MantineTheme) => ({
				tab: {
					transition: 'all 0.2s ease-in-out',
					color: 'var(--text-color) !important',
					marginTop: '0.2em',
					'&:hover': {
						backgroundColor: 'var(--background-color-1)'
					},
					'&[data-active] > div': {
						color: 'var(--primary-color) !important'
					}
				},
				tabLabel: {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					gap: '0.5em'
				}
			})
		},
		Pagination: {
			styles: (_: MantineTheme) => ({
				item: {
					backgroundColor: 'var(--background-color-1)',
					transition: 'all 0.2s ease-in-out',
					color: 'var(--text-color)',
					'&:hover:not([data-active])': {
						backgroundColor: 'var(--background-color-2)'
					}
				}
			})
		}
	}
};

export default function App(props: AppProps) {
	const { Component, pageProps } = props;

	return (
		<>
			<Head>
				<title>Glass</title>
				<meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width' />
				<meta
					name='description'
					content='Glass is a Minecraft Plugin that provides you with a dashboard for all your server needs. From subusers to FTP to analytics, all in one easy to use location.'
				/>
			</Head>

			<MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
				<Script src='/theme.js' />
				<Component {...pageProps} />
			</MantineProvider>

			<ToastContainer pauseOnFocusLoss={false} theme='dark' position='bottom-right' />
		</>
	);
}
